import React from 'react'
import Layout from '../components/layout'
import { useStaticQuery, graphql } from "gatsby"
import SEO from '../components/seo'
import Hero from '../components/hero'
import ProductsPage from '../components/productsPage'
import BreadCrumb from '../components/breadCrumb'



const Products = () => {
    const data = useStaticQuery(graphql`
        query ProductsPageQuery {
            allContentfulMetaDescription(filter: {position: {eq: "Products"}}) {
            edges {
                node {
                text
                }
            }
            }
        }
    `)
  if(!data.allContentfulMetaDescription.edges) {
    return <div>Loading...</div>
  }
  const descriptionMeta = data.allContentfulMetaDescription.edges[0].node.text ? data.allContentfulMetaDescription.edges[0].node.text : "Top Nitrile and Latex Gloves Manufacturer in Asia | Savie Glove by Savie Group"
    return (
        <Layout hideContact={false} showMarketingPopup={true}>
            <SEO title="Savie Glove Products" description={descriptionMeta} />
            <Hero position="Products"
                className="heroProducts"
            />
            <BreadCrumb className="products" breadCrumbTitle="Products" />
            <ProductsPage flipImage={true} />
        </Layout>
    )
}

export default Products
