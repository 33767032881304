import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ProductBlock from "./productBlock"



const Products = ({showSize, flipImage}) => {
    const data = useStaticQuery(graphql`
    query ProductsQuery {
        allContentfulProducts(sort: {fields: order}) {
          edges {
            node {
              image {
                file {
                  url
                  fileName
                }
                sizes(maxWidth: 900, quality: 90, resizingBehavior: PAD) {
                  ...GatsbyContentfulSizes_withWebp
                  sizes
                  src
                  srcSet
                  base64
                }
              }
              id
              slug
              sizes
              color
              order
              title
              text {
                text
              }
            }
          }
        }
      }
    `)

    if(!data.allContentfulProducts.edges) {
        return <div>Loading...</div>
      } else {
        return data.allContentfulProducts.edges.map((edge) => {
          if(edge.node) {
              return (
                        <ProductBlock key={edge.node.id} node={edge.node} showSize={showSize} flipImage={flipImage} />
                    )
            }
            return true
        })
      }
}

export default Products