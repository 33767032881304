import styled from 'styled-components'
import { device, width } from '../styles/device';
import {TitleWithTextStyled} from './titleWithTextStyled'
import * as palette from '../styles/variables'
import { lighten, darken } from 'polished'




export const ProductPageStyled = styled.div`
    padding: 50px 20px 80px 20px;
    display: flex;

    .contentWrap {
        width: ${width.xxl};
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${device.desktop} {
            flex-direction: row;
        }
    }

    .mainWrap {
        flex: 3 4;
        border-right: none;

        @media ${device.desktop} {
            /* border-right: 1px solid ${palette.borderGrey}; */
        }
    }

    .sideWrap {
        flex: 1 4;
        margin-left: 0;

        @media ${device.desktop} {
            margin-left: 20px;
        }

    }

    .productSection {
        padding-top: 50px;

        @media ${device.tablet} {
            padding-top: 30px;
        }
    }

    .imagesWrapper.packaging, .imagesWrapper.specs {
        .innerWrap {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .gatsby-image-wrapper {
                width: 100%;
                margin: 5px;
                flex: 100%;

                @media ${device.mobile} {
                    flex: 45%;
                }

                @media ${device.laptop} {
                    flex: 30%;
                }
            }
        }
    }
`;

export const ProductPageTitleInnerStyle = styled(TitleWithTextStyled)`

    .products {
        max-width: ${width.xxl};
        margin: 0 auto;
        text-align: left;

        @media ${device.tablet} {
            text-align: center;
        }

        .container {
            text-align: center;
            padding-left: 20px;
            padding-right: 20px;

            .title {
                margin-bottom: 1rem;
            }
        }
    }

    .specs {
        margin-top: 0;

        @media ${device.tablet} {
            margin-top: 3rem;
        }
    }

`;

