import React from "react"
import {ProductSectionStyled} from './productSectionStyled'
import Products from './products'
import TitleWithText from './titleWithText'
import PropTypes from 'prop-types'



const ProductSection = ({showSize, flipImage, hideTitle, className}) => {
    return (
        <ProductSectionStyled className={className}>
            <div className="containerProductSection">
                {!hideTitle && <TitleWithText slug="products" isLightText={false}/>}
                <div className="productsWrapper">
                    <Products showSize={showSize} flipImage={flipImage} />
                </div>
            </div>
        </ProductSectionStyled>
    )
}

ProductSection.propTypes = {
    hideTitle: PropTypes.bool,
    className: PropTypes.string
}

 export default ProductSection

