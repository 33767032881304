import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device } from '../styles/device';


export const ProductBlockStyled = styled.div`
    
    .flip-card {
            background-color: transparent;
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border: 1px solid ${palette.borderGrey};
    }

    .flip-card-back {
        display: none;
    }

    @media ${device.tablet} {
        .flip-card {
            background-color: transparent;
            width: 340px;
            height: 420px;
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            perspective: 1000px;
            border: 1px solid ${palette.borderGrey};
        }

        .flip-card-inner {
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
            transition: transform 0.6s;
            transform-style: preserve-3d;
        }

        .flip-card:hover .flip-card-inner {
            transform: ${props => props.flipImage ? `rotateY(180deg)` : `none`}; 
        }

        .flip-card-front, .flip-card-back {
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .flip-card-front {
    
        }

        .flip-card-back {
            transform: rotateY(180deg);
            display: block;
        }
    }

    .buttonWrapper {
        display: flex;
        margin-top: 1rem;

        .more-detail {
            a {
                padding: .3rem 1rem;
                font-size: ${palette.fontSizeSsm};
            }
        }

        .inquiry {
            margin-right: 10px;
            a {
                padding: .3rem 1rem;
                font-size: ${palette.fontSizeSsm};
            }
        }
    }
`;