import styled from 'styled-components'
import * as palette from '../styles/variables'
import { device, width } from '../styles/device'
import { lighten } from 'polished'
import {TitleWithTextStyled} from './titleWithTextStyled'




export const CertificateSectionStyled = styled.div`

        .cert {
            width: 120px;
            height: unset;

            @media ${device.desktop} {
                width: 80px;
                height: 70px;
            }

            .bow {
                fill: ${lighten(0.4, palette.cta)};
            }

            .paper path {
                fill: ${palette.cta};
            }
        }

        .certContent {
            /* padding-top: 20px;
            padding-bottom: 80px; */
            width: 100%;

            .certInner {
                background-color: ${palette.lightBg};
                height: 100%;
            }
        }
    
`;

export const CertificateSectionTitleStyled = styled(TitleWithTextStyled)`

/*     
        .container {
            text-align: center;

            .title {
                margin-bottom: 1rem;
                padding-left: 20px;
                padding-right: 20px;
            }
        } */

    .certText {

        .container {
            padding: 20px;
        }

        ul {
            padding-left: 20px;
        }

        .title, p, li {
            text-align: left;
        }

        .title {
            margin-bottom: 1rem;
            display: none;
        }
       
    }
        
    
`;
