import styled from 'styled-components'
import * as palette from '../styles/variables';


export const ColorSetStyled = styled.div`

    .colorGroupWrapper {
        display: flex;
        margin-bottom: 1rem;

        .colorBox {
            width: 15px;
            height: 15px;
            margin-right: 10px;
            border: 1px solid ${palette.mediumGrey};

            &.blue {
                background-color: ${palette.colorPickBlue};
            }
            &.white {
                background-color: ${palette.colorPickWhite};
            }
            &.black {
                background-color: ${palette.colorPickBlack};
            }
            &.purple {
                background-color: ${palette.colorPickPurple};
            }
        }
    }

`;