import React from "react"
import TitleWithText from './titleWithText'
import {ProductPageStyled, ProductPageTitleInnerStyle} from './productPageStyled'
import ProductSection from '../components/ProductSection'
import CertificateSection from './certificateSection'
import ImageGroup from './imageGroup'





const ProductsPage = ({flipImage}) => {
    return <ProductPageStyled>
                <div className="contentWrap">
                    <div className="mainWrap">
                        <ProductPageTitleInnerStyle>
                            <TitleWithText slug="our-products" className="products" isLightText={false}/>
                        </ProductPageTitleInnerStyle>
                        <ProductSection hideTitle={true} className="productSection" flipImage={flipImage} />
                        <ProductPageTitleInnerStyle>
                            <TitleWithText slug="packaging" className="packaging" isLightText={false}/>
                        </ProductPageTitleInnerStyle>
                        <ImageGroup slug="packaging" className="packaging" />
                        <ProductPageTitleInnerStyle>
                            <TitleWithText slug="products-specs" className="specs" isLightText={false}/>
                        </ProductPageTitleInnerStyle>
                        <ImageGroup slug="specs-table" className="specs" />

                    </div>
                    {/* <div className="sideWrap">
                        <CertificateSection className="certInProducts"/>
                    </div> */}
                </div>
            </ProductPageStyled>
}
 export default ProductsPage

