import React from 'react'
import Markdown from 'react-markdown'
import {ProductBlockStyled} from './productBlockStyled'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import ButtonCta from './buttonCta'
import emptyImage from '../images/image-symbol.jpg'
import ColorSet from './colorSet'




const ProductBlock = ({node, showSize, flipImage}) => {

    if(node.order && node.order < 0) {
        return null
    }

    const renderSizes = () => {
        const size = node.sizes && node.sizes.join(', ')
        return <span className="size">{size}</span>
    }
    return <ProductBlockStyled className="productCard" flipImage={flipImage}>

                <div className="flip-card">
                    <div className="flip-card-inner">
                        <div className="flip-card-front">
                            {node.image && node.slug && node.title &&
                                <div className={`imageWrap ${node.slug}`}>
                                    {node.image[0] ? <Img className="productImage" alt={node.title} sizes={node.image[0].sizes} placeholderStyle={{ visibility: "hidden" }} /> :
                                        <img src={emptyImage} className="productImage" alt={'No Image'} />
                                    }

                                </div>
                            }
                        </div>
                        <div className="flip-card-back">
                            {node.image && node.slug && node.title &&
                                <div className={`imageWrap ${node.slug}`}>
                                    {node.image[1] ? <Img className="productImage" alt={node.title} sizes={node.image[1].sizes} placeholderStyle={{ visibility: "hidden" }} /> :
                                        <img src={emptyImage} className="productImage" alt={'No Image'} />
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ColorSet colors={node.color} hideColorName={true}/>

                <div className="textWrapper">
                    {node.title && <h3 className="title">
                        {node.title}
                    </h3>}
                    {node.text.text && <div className="detail">
                        <Markdown source={node.text.text} />
                    </div>}
                   {showSize && renderSizes()}
                </div>

                <div className="buttonWrapper">
                    <ButtonCta text="Contact us" className="inquiry" linkTo={`/contact/`} isCta={true} />
                    <ButtonCta text="More detail" className="more-detail" linkTo={`/${node.slug ? `${node.slug}/` : ''}`} isCta={false} />
                </div>

        </ProductBlockStyled>
}

ProductBlock.propTypes = {
    flipImage: PropTypes.bool.isRequired
}


export default ProductBlock