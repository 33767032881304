import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';


export const ProductSectionStyled = styled.div`
        padding: 80px 20px 60px 20px;

        @media ${device.tablet} {
            padding: 80px 20px 80px 20px;
        }

    .containerProductSection {
        max-width: ${width.xxl};
        margin: 0 auto;

        .container {
            margin-bottom: 3rem;
        }
    }
    .productsWrapper {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: center;
        margin-top: 0;
        align-items: center;
        animation: bounce-in-right 1s;

        @media ${device.laptop} {
            flex-direction: row;
            margin-top: 2rem;
            align-items: flex-start;
        }

        .productCard {
            margin-right: 5px;
            margin-left: 5px;
            max-width: 340px;
            margin-bottom: 4rem;
            align-self: unset;

            @media ${device.laptop} {
                margin-bottom: 2rem;
            }

            .textWrapper {
                padding-right: 0;

                @media ${device.tablet} {
                    padding-right: 20px;
                }

                .title {
                    margin-bottom: .5rem;
                    font-weight: 400;
                    color: ${palette.cta};
                }

                .detail {
                    color: ${palette.lightText};

                    p {
                        margin-bottom: .5rem;
                    }
                }

                .size {
                    color: ${palette.lightText};
                    font-size: ${palette.fontSizeSm};
                }

            }
        }
    }

    @keyframes bounce-in-right {
        0% {
            opacity: 0;
            transform: translateX(2000px);
        }
        60% {

            transform: translateX(-30px);
        }
        80% {
            transform: translateX(10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

`;