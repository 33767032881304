import React, {useEffect, useState } from 'react'
import {ColorSetStyled} from './colorSetStyled'
import PropTypes from 'prop-types'




const ColorSet = ({colors, hideColorName}) => { 
    const [onColor, setOnColor] = useState('')
    useEffect(() => {
        if(colors && colors.length > 0) {
            setOnColor(colors[0])
        }
    }, []);
    
    const mouseEnter = (color) => {
        setOnColor(color)
    }

    const onClick = (color) => {
        setOnColor(color)
    }
    
    const renderColor = () => {
        return colors && colors.map((c, idx) => {
           switch(c) {
            case 'blue':
                return <div key={idx} className={`colorBox blue`} onMouseEnter={mouseEnter.bind('', c)} onClick={onClick.bind('', c)}></div>
            case 'white':
                return <div key={idx} className={`colorBox white`} onMouseEnter={mouseEnter.bind('', c)} onClick={onClick.bind('', c)}></div>
            case 'black':
                return <div key={idx} className={`colorBox black`} onMouseEnter={mouseEnter.bind('', c)} onClick={onClick.bind('', c)}></div>
            case 'purple':
                return <div key={idx} className={`colorBox purple`} onMouseEnter={mouseEnter.bind('', c)} onClick={onClick.bind('', c)}></div>
            default:
                return '';
            }
        })
    }

    return (
            <ColorSetStyled>
                {!hideColorName && <p className="colorNameWrap">Color:<span className="colorName"> {onColor}</span></p>}
                <div className="colorGroupWrapper">
                    {renderColor()}
                </div>
                
            </ColorSetStyled>
        )       
}

ColorSet.propTypes = {
    colors: PropTypes.array.isRequired,
    hideColorName: PropTypes.bool

}
  

export default ColorSet